import AndroidAppInstallIcon from './android-app-install-icon.svg';
import AppleShareIcon from './apple-share-icon.svg';
import CirclePlusIcon from './circle-plus-icon.svg';
import CloseIcon from './close-icon.svg';
import CopyIcon from './copy-icon.svg';
import HamburgerIcon from './hamburger-icon.svg';
import KebabIcon from './kebab-icon.svg';
import RedWarningIcon from './red-warning-icon.svg';
import SafariIcon from './safari-icon.svg';
import TriangleDownIcon from './triangle-down-icon.svg';
import CameraIcon from './camera-icon.svg';
import QrIcon from './qr-icon.svg';
import AchieveIcon from './achieve-icon.svg';
import ForwardArrowIcon from './forward-arrow-icon.svg';
import BearIcon from './bear-icon.svg';
import EllipsisIcon from './ellipsis-icon.svg';
import InfoIcon from './info-icon.svg';
import PhotoIcon from './photo-icon.svg';
import NoCertificateIcon from './no-certificate-icon.svg';
import Logo from './logo_asteeda.svg';
import CheckedCircleIcon from './checked-circle.svg';
import CancelCircleIcon from './cancel-circle.svg';

export {
  AndroidAppInstallIcon,
  AppleShareIcon,
  CirclePlusIcon,
  CloseIcon,
  CopyIcon,
  HamburgerIcon,
  KebabIcon,
  RedWarningIcon,
  SafariIcon,
  TriangleDownIcon,
  CameraIcon,
  QrIcon,
  AchieveIcon,
  ForwardArrowIcon,
  BearIcon,
  EllipsisIcon,
  InfoIcon,
  PhotoIcon,
  NoCertificateIcon,
  Logo,
  CheckedCircleIcon,
  CancelCircleIcon,
};
