//Unauthenticated routes
export const signInUrl = () => '/auth/sign-in';
export const ticketEntranceUrl = (ticketUniqueKey: string) => `/tickets/${ticketUniqueKey}`;
export const inquiryUrl = () => '/inquiry';
export const healthCheckUrl = () => '/health';
export const ocrSignInUrl = () => '/auth/step-2-sign-in';

//Authenticated routes
export const web3AuthCallbackUrl = () => '/auth/callback';

export const topPageUrl = () => '/top-page';
export const userTicketsUrl = () => '/user-tickets';

export const userTicketDetailUrl = (ticketUniqueKey?: string) => `/user-tickets/${ticketUniqueKey}`;

export const userNewUrl = (ticketUniqueKey?: string) => `/users/new?ticketUniqueKey=${ticketUniqueKey}`;
export const userProfileNewUrl = (ticketUniqueKey: string) => `/tickets/${ticketUniqueKey}/user-tickets/new`;
export const ticketDroppedUrl = (ticketUniqueKey?: string) => `/tickets/${ticketUniqueKey}/dropped`;

export const userDigitalCertificateUrl = () => '/user-tickets?tab=digital-certificate';
export const userDigitalCertificateDetailUrl = (digitalCertificateUniqueKey?: string) =>
  `/user-digital-certificate/${digitalCertificateUniqueKey}`;

export const qrEntranceUrl = () => '/qr/entrance';
export const ocrEntranceUrl = () => '/ocr/entrance';
export const userRegistrationCompletedUrl = () => '/users/registration-completed';
export const userOcrSurveyUrl = (ocrUniqueKey?: string) => `/ocr/${ocrUniqueKey}/user-survey`;
export const userDigitalCertificateDroppedUrl = (ocrUniqueKey?: string) =>
  `/user-digital-certificate/${ocrUniqueKey}/dropped`;
